<style lang="scss" scoped>
.exception {
  border: 1px solid #ccc;
  background: #f6f6f6;
  padding: 2px 3px;
  display: inline-block;
  border-radius: 2px;
  margin-right: 3px;
  margin-bottom: 3px;
  .r {
    display: inline-block;
    visibility: hidden;
    margin-left: 3px;
    margin-right: 3px;
    cursor: pointer;
  }
  &:hover {
    .r {
      visibility: visible;
    }
  }
}
</style>

<template>
  <div class="exception">
    {{ item.date }}
    <div class="r">
      <i @click="clicked" class="fas fa-times"></i>
    </div>
  </div>
</template>

<script>
export default {
  name: "Exception",
  props: ["item"],
  methods: {
    clicked() {
      this.$emit("remove", this.item.id);
    },
  },
};
</script>
